export interface CraftnoteEventProviderName {
	name : 'adjust' | 'firebase' | 'facebook' | 'iterable' | 'craftnote';
	eventName: string;
}

class CraftnoteEventError extends Error{}

export abstract class CraftnoteEvent<T>{
	appVersion: string = '0.0.1';
	providers: CraftnoteEventProviderName[] = [];
	parameters: T;

	constructor(parameters: T){
		this.parameters = parameters;
	}

	getProviders(): CraftnoteEventProviderName[]{
		return this.providers;
	}

	setProviders(providers: CraftnoteEventProviderName[]): void{
		this.providers = providers;
	}

	getParameters(): T{
		return this.parameters;
	}

	setParameters(parameters: T): void{
		this.parameters = parameters;
	}
}

export interface GlobalCraftnoteEventParameters {
  version: string;
  companyId: string;
  userId: string;
  isOnline: boolean;
  networkType: 'LTE'| 'WIFI'| 'UNKNOWN';
}

export function createCraftnoteEventBuilder<T, U, V extends (parameter: U) => CraftnoteEvent<U>>(
  specific: T,
  globalRequired: (keyof GlobalCraftnoteEventParameters)[],
  globalOffered: GlobalCraftnoteEventParameters,
  buildFunction: V,
): CraftnoteEvent<U> {
  const parameters: any = {
    ...specific,
  };

	//Add all mandatory global parameters
  for(const global of globalRequired){
    const offeredGlobal = globalOffered[global];
    if (offeredGlobal === null || offeredGlobal === undefined){
      throw new CraftnoteEventError(`Global Parameter "${global}" was requested but not provided`);
    }
    parameters[global] = globalOffered[global];
  }

	// Add additional optional global parameters
	for(const global of Object.keys(globalOffered)){
		if(parameters[global] !== undefined){
			continue;
		}
		parameters[global] = globalOffered[global];
	}
  return buildFunction(parameters as unknown as U);
}
